import React, { useEffect } from "react";
import useRazorpay from "react-razorpay";
import axios from "../api/axios";
import { useState } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import StatusToast from "../components/StatusToast";
import { toast } from "react-toastify";

// import env from "react-dotenv";

const SubcriptionPage = () => {
  const Razorpay = useRazorpay();
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [isEligible, setISEligible] = useState("");
  const navigate = useNavigate();
  const [isClickable, setIsClickable] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  //Get email ID

  const location = useLocation();
  const emailID = location?.state?.emailId;
  const mobile = location?.state?.mobile;
  const Amount =
    +location?.state?.amount || process.env.REACT_APP_SUBCRIPTION_AMOUNT;

  useEffect(() => {
    setEmail(emailID);
    setNumber(mobile);
  }, emailID);

  const login = async () => {
    navigate("/login");
  };

  const handlePayment = async () => {
    if (!email || !number) {
      return toast.error("Please Enter UserName Or Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    //code for cheak Valid User

    try {
      const response = await axios.post("/subcription/valid-userID", {
        userID: email,
      });

      if (!response.data.success) {
        return toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      console.error("Order Created Error : " + err);
    }

    var order;
    try {
      order = await axios.post("/create/order", {
        amount: Amount,
      });
    } catch (err) {
      console.error("Order Created Error : " + err);
    }
    const orderID = order?.data?.order?.id;
    const amount = order?.data?.amount;

    const options = {
      key: process.env.REACT_APP_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "E-Invoice Software pvt. Limited",
      description: "Test Transaction",
      image: "",
      order_id: orderID,
      callback_url: "http://localhost:3000/login",
      handler: async function (response) {
        const razorpay_payment_id = response.razorpay_payment_id;
        const razorpay_order_id = response.razorpay_order_id;
        const razorpay_signature = response.razorpay_signature;

        toast.success(
          "Congetulations  " +
            email +
            "  Now You Are Eligible To use This Software",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setISEligible("success");
        var result;
        try {
          result = await axios.post("/subcription/subcribe", {
            email: email,
            number: number,
            paymentStatus: "success",
            razorpay_payment_id: razorpay_payment_id,
            razorpay_order_id: razorpay_order_id,
            razorpay_signature: razorpay_signature,
          });
        } catch (err) {
          console.error("Verify User To Allow Login Error : " + err);
        }
      },
      prefill: {
        name: " E-Invoice User",
        email: email,
        contact: number,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#800000	",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      toast.error(response.error.code, {
        position: toast.POSITION.TOP_RIGHT,
      });
      toast.error(response.error.description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      toast.error(response.error.source, {
        position: toast.POSITION.TOP_RIGHT,
      });
      toast.error(response.error.step, {
        position: toast.POSITION.TOP_RIGHT,
      });
      toast.error(response.error.reason, {
        position: toast.POSITION.TOP_RIGHT,
      });
      toast.error(response.error.metadata.order_id, {
        position: toast.POSITION.TOP_RIGHT,
      });
      toast.error(response.error.metadata.payment_id, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });

    rzp1.open();
  };

  return (
    <>
      <div
        style={{
          alignItems: "center",
          backgroundColor: "#e9ecef",
          display: "flexbox",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <h2>
          If You Want To Use E-Invoice Software You Have To Pay Rs. {Amount}{" "}
          per/year
        </h2>
        <div className="login-box">
          <div className="login-logo">
            <b>E-</b>Invoice Subcription
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Please Enter Valid Data</p>
              <form>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="UserID"
                    value={email}
                    readOnly={true}
                    // onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user"></span>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Mo. Number"
                    value={number}
                    // readOnly={true}
                    // autoComplete="true"
                    // onChange={(e) => setNumber(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fa fa-phone"></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-10">
                    <div className="icheck-primary">
                      <input
                        className="mr-2"
                        type="checkbox"
                        id="remember"
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                        onClick={() => {
                          console.log(isChecked);
                          if (!isChecked) {
                            setIsClickable(false);
                          } else {
                            setIsClickable(true);
                          }
                        }}
                      />
                      <label htmlFor="remember">Accept terms & condition</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      type="button"
                      onClick={handlePayment}
                      className="btn btn-warning btn-block"
                      disabled={isClickable}
                    >
                      Pay Now
                    </button>
                  </div>
                  <br></br>
                  <br></br>
                  {isEligible == "success" ? (
                    <div className="col-12">
                      <button
                        type="button"
                        onClick={login}
                        className="btn btn-primary btn-block"
                      >
                        Login Page
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <StatusToast />
    </>
  );
};

export default SubcriptionPage;
