import React from "react";
import { ProgressBar } from "react-loader-spinner";

const statusLoader = ({ isLoading }) => {
  return (
    <>
      <center>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: "100px",
            display: "contents",
          }}
        >
          <ProgressBar
            height="150"
            width="150"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor="black"
            barColor="green"
            visible={isLoading}
          />
          <br />
          <h3>Loading........</h3>
        </div>
      </center>
    </>
  );
};

export default statusLoader;
