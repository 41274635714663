import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import StatusToast from "../components/StatusToast";
import axios from "../api/axios";
import { toast } from "react-toastify";

const AddClient = () => {
  const [GSTIN, setGSTIN] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [number, setNumber] = useState("");
  const [amount, setAmount] = useState();
  const [state, setState] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/addClient";
  console.log(location.pathname);
  //function for calling login api.
  const { auth, setAuth } = useAuth();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setAuth({ token: null, user: null });
  };

  const register = async () => {
    if (!email || !password || !number || !GSTIN || !amount) {
      return toast.error("Please Fill All Fields", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (number?.length != 10) {
      return toast.error("Please Enter Right Mo. Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    try {
      const registerpromise = await axios.post(
        "/register/register-client",
        {
          username: email,
          gstin: GSTIN,
          password: password,
          state: state,
          number: number,
          amount: amount,
        },
        {
          headers: {
            authorization: `Bearer ${auth.token}`,
          },
        }
      );
      //console.log(registerpromise);
      if (registerpromise.data.success) {
        setEmail("");
        setPassword("");
        setGSTIN("");
        setState("");
        setNumber("");
        setAmount("");

        toast.success(registerpromise.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        //navigate(from, { replace: true });
        toast.error(registerpromise.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went Wrong!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  return (
    <>
      {/* {auth?.token ? (
        <Navigate to="/" state={{ from: location }} replace />
      ) : (
        ""
      )} */}

      <div
        style={{
          alignItems: "center",
          backgroundColor: "#e9ecef",
          display: "flexbox",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <div className="register-box" data-select2-id={4}>
          <div className="register-logo">
            <a href="../base/index2.html">
              <b>Add</b> Client
            </a>
          </div>
          <div className="card">
            <div className="card-body register-card-body">
              <p className="login-box-msg">Register a new membership</p>
              <form action="#" method="post">
                <div className="input-group mb-3">
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      name="txt_gstin"
                      className="form-control"
                      value={GSTIN}
                      placeholder="GSTIN"
                      required
                      onChange={(e) => setGSTIN(e.target.value)}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-address-card" />
                      </div>
                    </div>
                  </div>
                  <input
                    type="text"
                    name="txt_username"
                    className="form-control"
                    value={email}
                    placeholder="Username"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    name="txt_password"
                    className="form-control"
                    value={password}
                    placeholder="Password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="number"
                    name="txt_password"
                    className="form-control"
                    value={number}
                    placeholder="Mobile Number"
                    required
                    onChange={(e) => setNumber(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fa fa-phone" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="number"
                    name="txt_password"
                    className="form-control"
                    value={amount}
                    placeholder="Subcription Amount"
                    required
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fa fa-money" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <select
                    name="txt_state"
                    id="state"
                    className="form-control select2 select2-hidden-accessible"
                    required=""
                    data-select2-id="state"
                    tabIndex={-1}
                    aria-hidden="true"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option value={null} data-select2-id={1}>
                      Select State
                    </option>
                    <option value={"Andhra Pradesh"} data-select2-id={2}>
                      Andhra Pradesh
                    </option>
                    <option value={"Andhra Pradesh (New)"} data-select2-id={5}>
                      Andhra Pradesh (New)
                    </option>
                    <option
                      value={"Andaman and Nicobar Islands"}
                      data-select2-id={6}
                    >
                      Andaman and Nicobar Islands
                    </option>
                    <option value={"Arunachal Pradesh"} data-select2-id={7}>
                      Arunachal Pradesh
                    </option>
                    <option value={"Assam"} data-select2-id={8}>
                      Assam
                    </option>
                    <option value={"Bihar"} data-select2-id={9}>
                      Bihar
                    </option>
                    <option value={"Chandigarh"} data-select2-id={10}>
                      Chandigarh
                    </option>
                    <option value={"Chhattisgarh"} data-select2-id={11}>
                      Chhattisgarh
                    </option>
                    <option
                      value={"Dadar and Nagar Haveli"}
                      data-select2-id={12}
                    >
                      Dadar and Nagar Haveli
                    </option>
                    <option value={"Daman and Diu"} data-select2-id={13}>
                      Daman and Diu
                    </option>
                    <option value={"Delhi"} data-select2-id={14}>
                      Delhi
                    </option>
                    <option value={"Ladakh"} data-select2-id={15}>
                      Ladakh
                    </option>
                    <option value={"Lakshadweep"} data-select2-id={16}>
                      Lakshadweep
                    </option>
                    <option value={"Puducherry"} data-select2-id={17}>
                      Puducherry
                    </option>
                    <option value={"Goa"} data-select2-id={18}>
                      Goa
                    </option>
                    <option value={"Gujarat"} data-select2-id={19}>
                      Gujarat
                    </option>
                    <option value={"Haryana"} data-select2-id={20}>
                      Haryana
                    </option>
                    <option value={"Himachal Pradesh"} data-select2-id={21}>
                      Himachal Pradesh
                    </option>
                    <option value={"Jammu and Kashmir"} data-select2-id={22}>
                      Jammu and Kashmir
                    </option>
                    <option value={"Jharkhand"} data-select2-id={23}>
                      Jharkhand
                    </option>
                    <option value={"Karnataka"} data-select2-id={24}>
                      Karnataka
                    </option>
                    <option value={"Kerala"} data-select2-id={25}>
                      Kerala
                    </option>
                    <option value={"Madhya Pradesh"} data-select2-id={26}>
                      Madhya Pradesh
                    </option>
                    <option value={"Maharashtra"} data-select2-id={27}>
                      Maharashtra
                    </option>
                    <option value={"Manipur"} data-select2-id={28}>
                      Manipur
                    </option>
                    <option value={"Meghalaya"} data-select2-id={29}>
                      Meghalaya
                    </option>
                    <option value={"Mizoram"} data-select2-id={30}>
                      Mizoram
                    </option>
                    <option value={"Nagaland"} data-select2-id={31}>
                      Nagaland
                    </option>
                    <option value={"Odisha"} data-select2-id={32}>
                      Odisha
                    </option>
                    <option value={"Punjab"} data-select2-id={33}>
                      Punjab
                    </option>
                    <option value={"Rajasthan"} data-select2-id={34}>
                      Rajasthan
                    </option>
                    <option value={"Sikkim"} data-select2-id={35}>
                      Sikkim
                    </option>
                    <option value={"Tamil Nadu"} data-select2-id={36}>
                      Tamil Nadu
                    </option>
                    <option value={"Telangana"} data-select2-id={37}>
                      Telangana
                    </option>
                    <option value={"Tripura"} data-select2-id={38}>
                      Tripura
                    </option>
                    <option value={"Uttar Pradesh"} data-select2-id={39}>
                      Uttar Pradesh
                    </option>
                    <option value={"Uttarakhand"} data-select2-id={40}>
                      Uttarakhand
                    </option>
                    <option value={"West Bengal"} data-select2-id={41}>
                      West Bengal
                    </option>
                    <option value={"OTHER TERRITORY"} data-select2-id={42}>
                      OTHER TERRITORY
                    </option>
                    <option value={"CENTRE JURISDICTION"} data-select2-id={43}>
                      CENTRE JURISDICTION
                    </option>
                  </select>
                </div>
                {/* /.col */}
                <div className="col-12">
                  <button
                    type="button"
                    name="btn_register"
                    onClick={(e) => {
                      register();
                    }}
                    className="btn btn-primary btn-block"
                  >
                    Register
                  </button>
                </div>
                {/* /.col */}
              </form>
            </div>
          </div>
          {/* /.form-box */}
        </div>
        {/* /.card */}
        {/* /.register-box */}
        <a className="nav-link" href="#" onClick={logout}>
          Logout
        </a>
        {/* Bootstrap 4 */}
        {/* AdminLTE App */}
        <StatusToast />
      </div>
    </>
  );
};

export default AddClient;
