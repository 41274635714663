import Home from "../pages/Home";
import Login from "../pages/Login";
import AddClient from "../pages/AddClient";
import EditClient from "../pages/EditClient";
import CreateInvoice from "../pages/CreateInvoice";
import InvoiceList from "../pages/InvoiceList";
import AddClientMaster from "../pages/AddClientMaster";
import InvoicePrint from "../pages/InvoicePrint";
import EinvoiceProcess from "../pages/EinvoiceProcess";
import EInvoicePrint from "../pages/EInvoicePrint";
import SubcriptionPage from "../pages/SubcriptionPage";
import RenewSubcription from "../pages/RenewSubcription";
import List from "../pages/List";
import PartyList from "../pages/PartyList";
import EditParty from "../pages/EditParty";

const path = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/addClient",
    component: AddClient,
    roles: ["admin"],
  },
  {
    path: "/editClient",
    component: EditClient,
    roles: ["admin"],
  },
  {
    path: "/createInvoice",
    component: CreateInvoice,
    roles: ["admin", "user"],
  },
  {
    path: "/invoiceList",
    component: InvoiceList,
    roles: ["admin", "user"],
  },
  {
    path: "/editParty",
    component: EditParty,
    roles: ["admin", "user"],
  },
  {
    path: "/add_client",
    component: AddClientMaster,
    roles: ["admin", "user"],
  },
  {
    path: "/printInvoice",
    component: InvoicePrint,
    public: true,
    roles: ["admin", "user"],
  },
  {
    path: "/einvoiceProcess",
    component: EinvoiceProcess,
    public: true,
    roles: ["admin", "user"],
  },
  {
    path: "/eInvoicePrint",
    component: EInvoicePrint,
    public: true,
    roles: ["admin", "user"],
  },
  {
    path: "/partyList",
    component: PartyList,
    roles: ["admin", "user"],
  },
  {
    path: "/subcription",
    component: SubcriptionPage,
    roles: ["admin", "user"],
    public: true,
  },
  {
    path: "/renewSubcription",
    component: RenewSubcription,
    public: true,
  },
  {
    path: "/subcription/user/list",
    component: List,
    roles: ["admin"],
  },
  {
    path: "/login",
    component: Login,
    public: true,
  },
];

export default path;
