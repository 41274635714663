import React from "react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader";
import swal from "sweetalert";

const InvoiceList = () => {
  const { auth } = useAuth();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [canLoading, setcanLoading] = useState(false);
  const [dropdownShow, setDropdownShow] = useState(false);
  const [activeDDId, setActiveDDId] = useState(null);

  const invoiceList = async () => {
    try {
      const response = await axios.get("/invoice/invoice-list", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      console.log(response.data);
      if (response?.data?.list == undefined) {
        toast.error("No Data Found", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setData(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    invoiceList();
  }, []);

  const ViewInvoice = async (uniqeID) => {
    navigate("/printInvoice", {
      state: {
        uniqeId: uniqeID,
      },
    });
  };

  const deleteInvoice = async (uniqeId) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (!willDelete) return;
    try {
      let response = axios.delete("/invoice/delete/" + uniqeId, {
        headers: { authorization: "Bearer " + auth.token },
      });
      response = await toast.promise(response, {
        pending: "Deleting Entry",
        success: "Entry Deleted Successfully!",
        error: "Something went Wrong!",
      });
      if (response.data.success) {
        await invoiceList();
      }
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const viewEInvoice = async (uniqeID) => {
    navigate("/eInvoicePrint", {
      state: {
        uniqeId: uniqeID,
      },
    });
  };

  const DateForamate = (inputDateString) => {
    if (!inputDateString) {
      return "";
    }
    const parts = inputDateString?.split("T")[0]?.split("-");
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const cancleEInvoice = async (uniqeID) => {
    console.log("Clicked");
    setcanLoading(true);

    try {
      const response = await axios.get("/eInvoice/cancle-eInvoice/" + uniqeID, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      });
      if (response?.data?.success) {
        swal("Done!", response?.data?.message, "success");
      } else {
        swal("Oops!", response?.data?.message, "error");
      }
    } catch (err) {
      console.log(err);
    }
    setcanLoading(false);
  };

  const generateEInvoice = async (uniqeID) => {
    console.log("Clicked");
    setisLoading(true);

    try {
      const response = await axios.post(
        "/eInvoice/generate-eInvoice",
        {
          uniqeId: uniqeID,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      console.log(response.data.Errors);
      if (response.data.success) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/eInvoicePrint", {
          state: {
            uniqeId: uniqeID,
          },
        });
      } else {
        // toast.error("Einvoice Not Generated", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        // toast.error("Please Go Back And Write Valid Parameter", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });

        navigate("/einvoiceProcess", {
          state: {
            uniqeId: uniqeID,
            Errors: response.data.Errors,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
    setisLoading(false);
  };

  // console.log(data.list);

  return (
    <>
      {" "}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>E-invoice List </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/invoiceList">E-invoice List</Link>
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              {/* /.card */}
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">E-invoice List</h5>
                  <Link to="/createInvoice">
                    <button
                      style={{
                        float: "right",
                        width: 118,
                        backgroundColor: "#2ea6ac",
                        color: "white",
                        height: 41,
                      }}
                    >
                      Add E-invoice
                    </button>
                  </Link>
                </div>
                {/* /.card-header */}
                <div className="card-body" style={{ overflowX: "auto" }}>
                  <table
                    id="example1"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Invoice Number</th>
                        <th>Invoice Date</th>
                        <th>Party Name</th>
                        <th>Ack Number</th>
                        <th>Ack Date</th>
                        <th>IRN</th>
                        <th>Total After Tax</th>
                        <th>Date Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.list?.length == 0 ? (
                        <h1>No Data Found</h1>
                      ) : (
                        <>
                          {data?.list?.map((item, index) => (
                            <React.Fragment>
                              <tr key={index}>
                                <td>
                                  <div
                                    className={
                                      dropdownShow && activeDDId == index
                                        ? "dropdown show"
                                        : "dropdown"
                                    }
                                  >
                                    <button
                                      onClick={() => {
                                        setActiveDDId(index);
                                        setDropdownShow(!dropdownShow);
                                      }}
                                      className="btn btn-primary dropdown-toggle"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      Action
                                      <span className="caret" />
                                    </button>
                                    <ul
                                      className={
                                        dropdownShow && activeDDId == index
                                          ? "dropdown-menu show"
                                          : "dropdown-menu"
                                      }
                                      style={{}}
                                    >
                                      <li
                                        onClick={() => {
                                          ViewInvoice(item?.uniqeID);
                                        }}
                                        style={{
                                          outline: "none",
                                          backgroundColor: "white",
                                          border: "none",
                                          color: "black",
                                          padding: "5px 12px",
                                          textAlign: "center",
                                          textDecoration: "none",
                                          display: "inline-block",
                                          fontSize: "14px",
                                          margin: "4px 10px",
                                          cursor: "pointer",
                                        }}
                                        onMouseEnter={(e) => {
                                          e.target.style.backgroundColor =
                                            "lightblue";
                                        }}
                                        onMouseLeave={(e) => {
                                          e.target.style.backgroundColor =
                                            "white";
                                        }}
                                      >
                                        View Invoice
                                      </li>
                                      <li
                                        onClick={() => {
                                          deleteInvoice(item?.uniqeID);
                                        }}
                                        style={{
                                          outline: "none",
                                          backgroundColor: "white",
                                          border: "none",
                                          color: "black",
                                          padding: "5px 12px",
                                          textAlign: "center",
                                          textDecoration: "none",
                                          display: "inline-block",
                                          fontSize: "14px",
                                          margin: "4px 10px",
                                          cursor: "pointer",
                                        }}
                                        onMouseEnter={(e) => {
                                          e.target.style.backgroundColor =
                                            "lightblue";
                                        }}
                                        onMouseLeave={(e) => {
                                          e.target.style.backgroundColor =
                                            "white";
                                        }}
                                      >
                                        Delete
                                      </li>
                                      {item?.ackNo == "undefined" ? (
                                        isLoading ? (
                                          <Loader isLoading={isLoading} />
                                        ) : (
                                          <li
                                            onClick={() => {
                                              generateEInvoice(item?.uniqeID);
                                            }}
                                            style={{
                                              outline: "none",
                                              backgroundColor: "white",
                                              border: "none",
                                              color: "black",
                                              padding: "5px 12px",
                                              textAlign: "center",
                                              textDecoration: "none",
                                              display: "inline-block",
                                              fontSize: "13px",
                                              margin: "4px 10px",
                                              cursor: "pointer",
                                            }}
                                            onMouseEnter={(e) => {
                                              e.target.style.backgroundColor =
                                                "lightblue";
                                            }}
                                            onMouseLeave={(e) => {
                                              e.target.style.backgroundColor =
                                                "white";
                                            }}
                                          >
                                            Generate E-Invoice
                                          </li>
                                        )
                                      ) : (
                                        <li
                                          onClick={() => {
                                            viewEInvoice(item?.uniqeID);
                                          }}
                                          style={{
                                            outline: "none",
                                            backgroundColor: "white",
                                            border: "none",
                                            color: "black",
                                            padding: "5px 12px",
                                            textAlign: "center",
                                            textDecoration: "none",
                                            display: "inline-block",
                                            fontSize: "14px",
                                            margin: "4px 10px",
                                            cursor: "pointer",
                                          }}
                                          onMouseEnter={(e) => {
                                            e.target.style.backgroundColor =
                                              "lightblue";
                                          }}
                                          onMouseLeave={(e) => {
                                            e.target.style.backgroundColor =
                                              "white";
                                          }}
                                        >
                                          View E-Invoice
                                        </li>
                                      )}
                                      {item?.ackNo != "undefined" ? (
                                        canLoading ? (
                                          <Loader isLoading={canLoading} />
                                        ) : (
                                          <li
                                            onClick={() => {
                                              cancleEInvoice(item?.uniqeID);
                                            }}
                                            style={{
                                              outline: "none",
                                              backgroundColor: "white",
                                              border: "none",
                                              color: "black",
                                              padding: "5px 12px",
                                              textAlign: "center",
                                              textDecoration: "none",
                                              display: "inline-block",
                                              fontSize: "14px",
                                              margin: "4px 10px",
                                              cursor: "pointer",
                                            }}
                                            onMouseEnter={(e) => {
                                              e.target.style.backgroundColor =
                                                "lightblue";
                                            }}
                                            onMouseLeave={(e) => {
                                              e.target.style.backgroundColor =
                                                "white";
                                            }}
                                          >
                                            Cancle E-Invoice
                                          </li>
                                        )
                                      ) : (
                                        ""
                                      )}

                                      {/* {item?.ackNo != "undefined" ? (
                                        <li
                                          onClick={() => {
                                            cancleEInvoice(item?.uniqeID);
                                          }}
                                          style={{
                                            outline: "none",
                                            backgroundColor: "white",
                                            border: "none",
                                            color: "black",
                                            padding: "5px 12px",
                                            textAlign: "center",
                                            textDecoration: "none",
                                            display: "inline-block",
                                            fontSize: "14px",
                                            margin: "4px 10px",
                                            cursor: "pointer",
                                          }}
                                          onMouseEnter={(e) => {
                                            e.target.style.backgroundColor =
                                              "lightblue";
                                          }}
                                          onMouseLeave={(e) => {
                                            e.target.style.backgroundColor =
                                              "white";
                                          }}
                                        >
                                          Cancle E-Invoice
                                        </li>
                                      ) : (
                                        ""
                                      )} */}
                                    </ul>
                                  </div>
                                </td>
                                <td>{item?.invoiceNumber}</td>
                                <td>{DateForamate(item?.invoiceDate)}</td>
                                <td>{item?.partyname}</td>
                                <td>{item?.ackNo}</td>
                                <td>{item?.ackDate}</td>
                                <td style={{ wordBreak: "break-all" }}>
                                  {item?.irn}
                                </td>
                                <td>
                                  {parseFloat(item?.total_after_tax).toFixed(2)}
                                </td>
                                <td>{item?.dateTime}</td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
        <StatusToast />
      </section>
    </>
  );
};

export default InvoiceList;
