import Header from "../components/header/Header";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react";
import axios, { CancelToken } from "../api/axios";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import swal from "sweetalert";

//SubcriptionAmountModel
const SubcriptionAmountModel = ({ data, handleClose, show, loadData }) => {
  console.log(loadData);
  const { auth } = useAuth();

  const [amount, setAmount] = useState(data?.data?.subcriptionAmount || "");
  const [isSubmit, setIsSubmit] = useState(false);
  const [change, setchange] = useState(false);
  const editSubcriptionAmount = async () => {
    if (isSubmit) return;
    if (!amount) {
      toast("Missing Subcription Amount!", { type: "warning" });
      return;
    }
    setIsSubmit(true);
    console.log(data?.data?.email);
    console.log(amount);
    try {
      let response = axios.post(
        "/subcription/edit",
        {
          email: data?.data?.email,
          SubcriptionAmount: amount,
        },
        {
          headers: { authorization: "Bearer " + auth.token },
        }
      );
      response = await toast.promise(response, {
        pending: "Edited Amount..",
        success: "Amount Updated Successfully!",
        error: "Something went Wrong!",
      });
      setAmount("");
      handleClose();
      // const cancelToken = CancelToken.source();
      // loadData(cancelToken);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Subcription Amount</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="status">Subcription Amount</label>
          <br />
          <input
            className="form-control"
            id="status"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            placeholder="Amount"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={editSubcriptionAmount}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ActionDropdown = (data, loadData) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { auth } = useAuth();
  const navigate = useNavigate();

  const handleDelete = async () => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (!willDelete) return;
    try {
      let id = data?.data?._id;
      let response = axios.delete("/register/delete/" + id, {
        headers: { authorization: "Bearer " + auth.token },
      });
      response = await toast.promise(response, {
        pending: "Deleting Entry",
        success: "Entry Deleted Successfully!",
        error: "Something went Wrong!",
      });
      const cancelToken = CancelToken.source();
      loadData(cancelToken);
    } catch (err) {
      console.log(err);
    }
  };
  const handleEdit = () => {
    console.log(data);
    navigate("/editClient", { state: data?.data });
  };
  const statusChange = async (email, num) => {
    const willChange = await swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (!willChange) return;
    try {
      let response = axios.post(
        "/register/status",
        { email: email, num: num },
        { headers: { authorization: "Bearer " + auth.token } }
      );
      response = await toast.promise(response, {
        pending: "Changing Status...",
        success: "Status Changed Successfully!",
        error: "Something went Wrong!",
      });
      console.log(response.data);

      const cancelToken = CancelToken.source();
      loadData(cancelToken);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <DropdownButton id="dropdown-basic-button" title="Action">
        <Dropdown.Item onClick={handleShow}>Update Amount</Dropdown.Item>
        {console.log(data?.data?.subcription)}
        {data?.data?.subcription != 1 ? (
          <Dropdown.Item
            onClick={() => {
              statusChange(data?.data?.email, 1);
            }}
          >
            Active
          </Dropdown.Item>
        ) : (
          <Dropdown.Item
            onClick={() => {
              statusChange(data?.data?.email, 0);
            }}
          >
            Not Active
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={handleEdit}>Edit</Dropdown.Item>
        <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
      </DropdownButton>
      {show && (
        <SubcriptionAmountModel
          show={show}
          handleClose={handleClose}
          data={data}
          loadData={loadData}
        />
      )}
      <StatusToast />
    </>
  );
};

const List = () => {
  const { SearchBar } = Search;
  const [data, setData] = useState([]);
  const { auth } = useAuth();
  const loadData = async (cancelToken) => {
    try {
      const response = await axios.get("/subcription/list", {
        headers: { authorization: "bearer " + auth.token },
        cancelToken: cancelToken.token,
      });
      console.log(response.data);
      if (response?.data?.success) {
        setData(response.data.list);
      }
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    const cancelToken = CancelToken.source();
    loadData(cancelToken);
  }, []);

  const columns = [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      sort: true,
      formatExtraData: loadData,
      formatter: (c, row, i, extra) => {
        return <ActionDropdown data={row} loadData={extra} />;
      },
    },
    {
      dataField: "email",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "mobile",
      text: "Mobile Number",
    },
    {
      dataField: "gstin",
      text: "GSTIN Number",
    },
    {
      dataField: "subcriptionAmount",
      text: "Subcription Amount",
    },
    {
      dataField: "subcription",
      isDummyField: true,
      text: "Subcription",
      formatter: (c, row) => {
        console.log(row?.subcription);
        if (row.subcription == 1) {
          return <h5 style={{ color: "green" }}>Active</h5>;
        } else if (row.subcription == 0) {
          return <h5 style={{ color: "red" }}>Not Active</h5>;
        } else {
          return <h5 style={{ color: "red" }}>Expired</h5>;
        }
      },
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return (
    <>
      <Header page="User List" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Users</h3>
              </div>
              <div className="card-body">
                <ToolkitProvider
                  keyField="id"
                  data={data}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <div
                        className="d-flex justify-content-end"
                        id="search_box"
                      >
                        <h5>Input something at below input field:</h5>
                        <SearchBar {...props.searchProps} />
                      </div>
                      <hr />
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        noDataIndication="Table is Empty"
                        striped
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                        filter={filterFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
        <StatusToast />
      </section>
    </>
  );
};
export default List;
