import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";

const EinvoiceProcess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const uniqeID = location?.state?.uniqeId;
  const Errors = location?.state?.Errors;
  const handleGoBack = () => {
    // window.history.back();
    // window.history.back();
    navigate("/createInvoice", {
      state: {
        uniqeID: uniqeID,
        path: "EinvoiceProcess",
      },
    });
  };

  return (
    <>
      <center>
        <h1>E-Invoice Generate Process</h1>
        <h2>
          Errors Are Occured Please Read Properly And Update Your Data To
          Generate E-Invoice
        </h2>
        <div style={{}}>
          {Errors?.map((item, index) => {
            return (
              <>
                {index + 1} {" . "}
                <span>{item.ErrorMessage}</span>
                <br></br>
              </>
            );
          })}

          <button
            onClick={handleGoBack}
            style={{
              outline: "none",
              backgroundColor: "green",
              border: "none",
              color: "white",
              padding: "15px 32px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              margin: "4px 2px",
              cursor: "pointer",
            }}
            className="btn-convert"
          >
            Click To Go Back
          </button>
        </div>
      </center>
    </>
  );
};

export default EinvoiceProcess;
