import { useState } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import axios from "../api/axios";
import StatusToast from "../components/StatusToast";
import { toast } from "react-toastify";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [subcribeButton, setSubcribeButton] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  //Navigation Logic For Subcription Pages

  const Subcription = () => {
    navigate("/subcription", { replace: true });
  };

  const RenewSubcription = () => {
    navigate("/subcription", { replace: true });
  };
  //function for calling login api.
  const { auth, setAuth } = useAuth();
  const signIn = async () => {
    if (isSubmit) return;
    setIsSubmit(true);
    if (!email || !password) {
      setIsSubmit(false);
      return;
    }
    try {
      const response = await axios.post("/login", {
        email: email,
        password: password,
      });
      // const response = await toast.promise(loginpromise, {
      //   success: (await loginpromise).data.message,
      //   error: "Something went Wrong!",
      // });

      if (!response.data.success) {
        toast.error("Please check your email or password!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      if (response?.data?.token) {
        /**
         * code for cheak the user is purchase subcription or not
         */

        if (response?.data?.user?.subcription == 1) {
          toast.success("Logged in Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });

          setAuth({ token: response.data.token, user: response.data.user });
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          if (response.data?.user?.role === "admin") {
            navigate("/addClient", { replace: true });
          } else {
            navigate(from, { replace: true });
          }
        } else if (response?.data?.user?.subcription == 0) {
          toast.error(
            "You Are Not illegeble To Use This Software , To Use Software Please Take Subcription",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          navigate("/subcription", {
            state: {
              emailId: email,
              mobile: response?.data?.user?.mobile,
              amount: response?.data?.user?.subcriptionAmount,
            },
          });
          setSubcribeButton(
            <div className="col-12">
              <button
                type="button"
                onClick={(e) => {
                  Subcription();
                }}
                className="btn btn-warning btn-block"
              >
                Click To Take Subcription
              </button>
            </div>
          );
        } else {
          toast.error(
            "Your Subcription is Expired , To Use Software Please Renew Your Subcription",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setSubcribeButton(
            <div className="col-12">
              <button
                type="button"
                onClick={(e) => {
                  RenewSubcription();
                }}
                className="btn btn-warning btn-block"
              >
                Click To Renew Subcription
              </button>
            </div>
          );
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went Wrong!");
    } finally {
      setIsSubmit(false);
    }
  };
  return (
    <>
      {auth?.token ? (
        <Navigate to="/" state={{ from: location }} replace />
      ) : (
        ""
      )}

      <div
        style={{
          alignItems: "center",
          backgroundColor: "#e9ecef",
          display: "flexbox",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        <div className="login-box">
          <div className="login-logo">
            <b>E-</b>Invoice
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Sign in to start your session</p>

              <form>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Username"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    autoComplete="true"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <div className="icheck-primary">
                      <input className="mr-2" type="checkbox" id="remember" />
                      <label htmlFor="remember">Remember Me</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      type="button"
                      onClick={(e) => {
                        signIn();
                      }}
                      className="btn btn-primary btn-block"
                    >
                      Sign In
                    </button>
                  </div>
                  <br></br>
                  <br></br>
                  {subcribeButton}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <StatusToast />
    </>
  );
};
export default Login;
