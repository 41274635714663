import { useRef, useState } from 'react';
import Header from '../components/header/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import StatusToast from '../components/StatusToast';
import axios, { CancelToken } from '../api/axios';
import useAuth from '../hooks/useAuth';
import { useEffect } from 'react';

const EditParty = () => {
  const location = useLocation();
  const data = location.state;
  const [partyname, setpartyname] = useState(data?.partyname || '');
  const [address1, setaddress1] = useState(data?.address1 || '');
  const [address2, setaddress2] = useState(data?.address2 || '');
  const [fullAddress, setfullAddress] = useState(data?.fullAddress || '');
  const [mobile, setmobile] = useState(data?.mobile || '');
  const [gstin, setgstin] = useState(data?.gstin || '');
  const [pincode, setpincode] = useState(data?.pincode || '');
  const [username, setusername] = useState(data?.username || '');

  const navigate = useNavigate();
  const [isSubmit, setisSubmit] = useState(false);
  const { auth } = useAuth();

  const EditParty = async () => {
    if (isSubmit) return;
    setisSubmit(true);

    try {
      const url = '/client/edit';
      const response = await axios.post(
        url,
        {
          partyname,
          fullAddress,
          address1,
          address2,
          pincode,
          mobile,
          gstin,
        },
        {
          headers: { authorization: 'bearer ' + auth.token },
        },
      );
      console.log(response);
      if (response?.data?.success) {
        swal({
          title: 'Success!',
          text: `${response.data.message}`,
          icon: 'success',
        });

        navigate('/partyList');
      } else {
        swal({
          title: 'Failure!',
          text: `${response.data.message}`,
          icon: 'error',
        });
      }
    } catch (err) {
      swal({ title: 'Error!', text: `${err.message}`, icon: 'error' });
    } finally {
      setisSubmit(false);
    }
  };

  return (
    <>
      <Header page="Edit Client" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                <h3 className="card-title">Edit Client</h3>
              </div>
              <form className="needs-validation" noValidate>
                <div className="card-body">
                  <div className="form-group">
                    <div className="row" id="2">
                      <div className="col-md-3">
                        <label htmlFor="name">Company Name:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter Company Name"
                          value={partyname}
                          onChange={(e) => setpartyname(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="username">Address: </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="text"
                          placeholder="Enter Parent Name"
                          value={fullAddress}
                          onChange={(e) => setfullAddress(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="username">Address 1: </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="text"
                          placeholder="Enter Address1"
                          value={address1}
                          onChange={(e) => setaddress1(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="username">Address 2: </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="text"
                          placeholder="Enter Address2"
                          value={address2}
                          onChange={(e) => setaddress2(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="mobile">Mobile:</label>
                        <input
                          required
                          type="number"
                          className="form-control"
                          id="mobile"
                          value={mobile}
                          placeholder="Enter Mobile "
                          onChange={(e) => setmobile(e.target.value)}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="whom">GSTIN:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          readOnly
                          id="customerAge"
                          placeholder="Enter Customer Age"
                          value={gstin}
                          onChange={(e) => setgstin(e.target.value)}
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="whom">Pincode:</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="monthly Income"
                          placeholder="Enter Monthly Income"
                          value={pincode}
                          onChange={(e) => setpincode(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                  <div className="card-footer">
                    <input
                      style={{
                        backgroundColor: '#00a65a',
                        color: 'white',
                        width: '15%',
                        height: '38px',
                        border: 'none',
                        marginRight: '40px',
                      }}
                      type="button"
                      value="Save"
                      name="add_save"
                      disabled={isSubmit}
                      onClick={EditParty}
                    />
                  </div>
                  <StatusToast />
                </center>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default EditParty;
