import React from "react";
import { useState } from "react";
import axios from "../api/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import StatusToast from "../components/StatusToast";
import { useEffect } from "react";
import useAuth from "../hooks/useAuth";
const AddClientMaster = () => {
  const [GSTIN, setGSTIN] = useState("");
  const { auth } = useAuth();
  const submit = async (e) => {
    // e.preventDefault();
    if (!GSTIN) {
      return toast.error("Enter GSTIN Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (GSTIN.length != 15) {
      return toast.error("Please Enter Right GSTIN Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    try {
      const response = await axios.post(
        "/client/add",
        {
          gstin: GSTIN,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      if (response.data.success) {
        setGSTIN("");

        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div style={{ minHeight: 581 }}>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Add Client</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="index.php">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Add Add Client</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="card card-default">
            <div
              className="card-header"
              style={{ backgroundColor: "lightgrey" }}
            >
              <h3 className="card-title">
                Use the form below to add Add Client to master
              </h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool">
                  <Link to="/createInvoice">
                    {" "}
                    <i className="fas fa-edit"> Create-Invoice</i>
                  </Link>
                </button>
                {/*<button type="button" class="btn btn-tool">*/}
                {/*  <a href="http://ptecherp.slicebeans.com/admin/emp_master_add.php"><i class="fas fa-plus-square"> ADD</i></a>*/}
                {/*</button>*/}
              </div>
            </div>
            {/* /.card-header */}
            <form
              role="form"
              method="post"
              action="#"
              id="contact-form"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="card-body">
                <div className="row">
                  <input
                    type="text"
                    name="user_email"
                    defaultValue="API_SYNCINFO"
                    className="form-control"
                    id=""
                    hidden={true}
                  />
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>GSTIN</label>
                      <input
                        type="text"
                        name="gstin"
                        className="form-control gstin"
                        id="gstin"
                        placeholder="Enter GSTIN"
                        value={GSTIN}
                        required
                        onChange={(e) => setGSTIN(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* /.form-group */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
              {/* /.card-body */}
              <div className="card-footer">
                <input
                  type="submit"
                  defaultValue="Submit"
                  name="add_record"
                  className="btn btn-default float-right"
                  onClick={(e) => submit(e)}
                />
              </div>
            </form>
          </div>
        </section>
        {/* /.content */}
        <StatusToast />
      </div>
    </>
  );
};

export default AddClientMaster;
