import Navbar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebars/Sidebar";
import Footer from "../pages/Footer";
const Layout = ({ children }) => {
  const Children = children;
  return (
    <>
      <div className="wrapper">
        <Navbar />
        {/* <Sidebar /> */}
        {/* <div className="content-wrapper"> */}
        <Children />
      </div>
      <Footer />
    </>
  );
};

export default Layout;
