import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="main-footer" style={{ marginLeft: 0 }}>
      <strong>
        Copyright © 2024 <Link to="/">E-Invoice</Link>.
      </strong>
      All rights reserved.
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 2.0
      </div>
    </footer>
  );
};

export default Footer;
